.custom-file-input ~ .custom-file-label::after {
    content: "Parcourir";
}

.wrapper {
    display: flex;
    align-items: stretch;
}

#sidebar {
    min-width: 250px;
    max-width: 250px;
    min-height: 100vh;
    background-color: aliceblue;
}

#sidebar.active {
    margin-left: -250px;
}
a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

@media (max-width: 768px) {
    #sidebar {
        margin-left: -250px;
    }
    #sidebar.active {
        margin-left: 0;
    }
}

@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";


body {
    font-family: 'Poppins', sans-serif;
    background: #fafafa;
}

p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #E95420;
}

a, a:hover, a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

#navbarTop {
    color: #E95420;
    transition: all 0.3s;
}

#navbarTop a.nav-link {
    color: #E95420 !important;
}

#sidebar {
    /* don't forget to add all the previously mentioned styles here too */
    background: #f8f8f8;
    color: #E95420;
    transition: all 0.3s;
}

#sidebar .sidebar-header {
    padding: 20px;
    background: #f8f8f8;
}

#sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #e7e7e7;
}

#sidebar ul p {
    color: #E95420;
    padding: 10px;
}
h3 a {
    color: #E95420;
}
h3 a:hover {
    color: #E95420;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
    color: #E95420;
}
#sidebar ul li a:hover {
    color: #E95420;
    background: #E7E7E7;
}

#sidebar ul li.active > a, a[aria-expanded="true"] {
    color: #E95420;
    background: #E7E7E7;
}
#sidebar ul li {
    color: #E95420 !important;
}
ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #E7E7E7;
}
._loginForm ._btn-pocheco {
    color: #fff;
    background-color: #e95420;
    border-color: #e95420;
}
/*Login Form*/
._loginForm input {
    margin-bottom: 15px;
}
/*CRUD*/
a._crudEditBtn {
    width: 33px;
    height: 33px;
    padding: 0;
    color: #fff;
    background-color: #c34113;
    border-color: #b93e12;
}
a._crudDeleteBtn, button._crudDeleteBtn {
    width: 33px;
    height: 33px;
    padding: 0;
    color: #333;
    background-color: #d4d4d4;
    border-color: #8c8c8c;
}
a._crudDeleteBtn i, a._crudEditBtn i {
    margin-top: 7px;
}

a.btn._crudAddBtn, button._crudAddBtn {
    color: #fff;
    background-color: #c34113;
    border-color: #b93e12;
}
a._templateBtn, button._templateBtn {
    color: #000;
    background-color: #fff;
    border-color: #bdbdbd;
}

a._userSocietyLinkBtn, button._userSocietyLinkBtn {
    padding: 0;
    width: 33px;
    height: 33px;
    background-color: #c2694a;
    color: #fff;
    border-color: #8c4b35;
}
.container {
    margin-top: 100px;
}
._courrierLogo {
    max-height: 120px;
    max-width: 150px;
    margin-bottom: 20px;
}
._courrierHeader {
    max-width: 950px;
}
.btn-sq-lg {
    width: 300px !important;
    height: 300px !important;
    padding: 40px !important;
    color: #E95420 !important;
}

.btn-sq-md {
    width: 150px !important;
    height: 150px !important;
    padding: 20px !important;
    color: #E95420 !important;
}

.btn-lb {
    color: #E95420 !important;
}

.btn-cfrm {
    display: flex;
    flex-direction: column;
    margin-left: 75px;
    margin-right: 75px;
    font-size: 15px;
    min-width: 230px;
}

.btn-sq {
    width: 100px !important;
    height: 100px !important;
    font-size: 10px;
}

.btn-sq-sm {
    width: 50px !important;
    height: 50px !important;
    font-size: 10px;
}

.btn-sq-xs {
    width: 25px !important;
    height: 25px !important;
    padding:2px;
}
._cpField {
    width: 100px;
}
.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 24px;
    line-height: 1.33;
}

.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
}
.btn:hover, a.btn:hover {
    color: white;
}
a.btn-sq-lg:hover, btn-sq-lg:hover {
    color: #E95420 !important;
}

.filter_btn {
    margin-bottom: 15px;
}
.page-item.active .page-link {
    color: #fff !important;
    background-color: #E95420 !important;
    border-color: #E95420 !important;
}
.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #E95420 !important;
    background-color: #fff;
    border: 1px solid #dee2e6;
}
._btnEcopli {
    background-color: #ffecca !important;
}